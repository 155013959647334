<template>
  <div class="content">
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
            <div v-if="filtered" class="card-body p-0">
              <div v-if="fetched" class="table-responsive">
                <table
                  v-if="insights.total.sent > 0"
                  class="table table-striped"
                >
                  <thead>
                    <tr>
                      <th scope="col">{{ $tc('generic-str.acc', 1) }}</th>
                      <th v-if="insights.total.delivered > 0" scope="col">
                        {{ $tc('generic-str.delivered', 1) }}
                      </th>
                      <th v-if="insights.total.failed > 0" scope="col">{{ $tc('generic-str.failure', 2) }}</th>
                      <th v-if="insights.total.optout > 0" scope="col">Optout</th>
                      <th v-if="insights.total.read > 0" scope="col">Lidas</th>
                      <th v-if="insights.total.sent > 0" scope="col">
                        {{ $tc('generic-str.status.lbl-sent', 2) }}
                      </th>
                      <th v-if="insights.total.response > 0" scope="col">
                        Respostas
                        <img style="width: 17px; height: 17px; margin-left: 5px; margin-bottom: 2.3px;" src="assets/img/icons/info-tolltip.svg" class="circle-info ms-3" v-tooltip.top="'Soma de todos os status de retorno do cliente.'"/>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="insights.total.sent > 0">
                      <td><b>TOTAL</b></td>
                      <td v-if="insights.total.delivered > 0">{{ insights.total.delivered }}</td>
                      <td v-if="insights.total.failed > 0">{{ insights.total.failed }}</td>
                      <td v-if="insights.total.optout > 0">{{ insights.total.optout }}</td>
                      <td v-if="insights.total.read > 0">{{ insights.total.read }}</td>
                      <td v-if="insights.total.sent > 0">{{ insights.total.sent }}</td>
                      <td v-if="insights.total.response > 0">{{ insights.total.response }}</td>
                    </tr>
                    <tr v-else>
                      <td><b>TOTAL</b></td>
                      <td v-if="insights.total.delivered > 0">{{ insights.total.delivered }}</td>
                      <td v-if="insights.total.failed > 0">{{ insights.total.failed }}</td>
                      <td v-if="insights.total.optout > 0">{{ insights.total.optout }}</td>
                      <td v-if="insights.total.read > 0">{{ insights.total.read }}</td>
                      <td v-if="insights.total.sent > 0">{{ insights.total.sent }}</td>
                      <td v-if="insights.total.response > 0">{{ insights.total.response }}</td>
                    </tr>
                  <template v-for="(insight, id) in insights">
                    <tr v-if="insight[0]" :key="id">
                      <td> {{ insight.account[0] }} </td>
                      <template v-for="ins in insight">
                        <td v-if="ins.origin === 'BUSINESS'" :key="ins">{{ ins.count }}</td>
                      </template>
                      <td> {{ insight.totalResponses }} </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <div v-else class="text-center table-placeholder">
                  <i class="fas fa-envelope font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('sms-report-component.none')}}</h5>
                </div>
              </div>
              <div class="loading min-h-300" v-else>
                <LoadingAnim />
              </div>
            </div>
            <div v-else class="text-center table-placeholder">
              <i class="fas fa-envelope font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('sms-report-component.none')}}</h5>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
import axios from '@/plugins/axios';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// import PageHeader from '@/components/PageHeader.vue';
const webApi = axios();

export default {
  name: 'SmsDashboard',
  components: {
    // PageHeader,
    LoadingAnim,
  },
  props: ['startDate', 'endDate', 'filters'],
  data() {
    return {
      fetched: false,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      filtered: false,
      customers: [],
      insights: [],
      total: {},
      // beginDate: '',
      // endDate: '',
      form: {
        account_id: {
          in: [],
        },
        created_at: {
          gte: '',
          lte: '',
        },
      },
    };
  },
  created() {
    this.fetchCustomers();
    this.fetch();
  },
  methods: {
    hasBusinessOrigin(insight) {
      return Object.values(insight).some((obj) => obj.origin === 'BUSINESS');
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch() {
      this.filtered = true;
      const params = [];
      if (this.filters.gte) {
        params.push(`timestamp;gte;'${this.filters.gte}'`);
      } else {
        console.log('NO FILTERS GTE FROM PROPS');
      }
      if (this.filters.lte) {
        params.push(`timestamp;lte;'${this.filters.lte}'`);
      } else {
        console.log('NO FILTERS LTE FROM PROPS');
      }
      if (this.$store?.state?.account) {
        params.push(`whatsapp_business.account_id;eq;'${this.$store?.state?.account.id}'`);
      }

      // If filter contains customers id's add it to params, else get all customers.
      if (this.filters.account_id.in && this.filters.account_id.in.length > 0) {
        params.push(`&page=0&orderBy=timestamp;desc&customers[]=${this.filters.account_id.in}`);
      } else {
        params.push(`&page=0&orderBy=timestamp;desc&customers[]=${this.$store?.state?.account.id}`);
      }

      console.log('PARAMS AQUI PRA DEBUGAAAAAR', params);

      this.fetched = false;
      webApi.get(`/whatsapp/new/history-count?query[]=${params.join('&query[]=')}`).then(
        (response) => {
          let clientResponse = 0;
          const total = {
            delivered: 0,
            failed: 0,
            optout: 0,
            read: 0,
            sent: 0,
            response: 0,
          };
          this.insights = response.data;
          this.insights.forEach((insight) => {
            for (let i = 0; i < Object.keys(insight).length; i += 1) {
              if (insight[i]?.origin && insight[i]?.origin === 'CLIENT') {
                console.log(insight[i].count);
                clientResponse += parseInt(insight[i].count, 10);
              }
              insight[i]?.status === 'DELIVERED' && insight[i]?.origin === 'BUSINESS' ? total.delivered += parseInt(insight[i].count, 10) : 0;
              insight[i]?.status === 'FAILED' && insight[i]?.origin === 'BUSINESS' ? total.failed += parseInt(insight[i].count, 10) : 0;
              insight[i]?.status === 'OPTOUT' && insight[i]?.origin === 'BUSINESS' ? total.optout += parseInt(insight[i].count, 10) : 0;
              insight[i]?.status === 'READ' && insight[i]?.origin === 'BUSINESS' ? total.read += parseInt(insight[i].count, 10) : 0;
              insight[i]?.status === 'SENT' && insight[i]?.origin === 'BUSINESS' ? total.sent += parseInt(insight[i].count, 10) : 0;
              insight[i]?.origin === 'CLIENT' ? total.response += parseInt(insight[i].count, 10) : 0;
            }
            insight.totalResponses = clientResponse;
            this.insights.total = total;
          });
        },
        (error) => {
          this.content = error;
        },
      ).finally(() => {
        this.fetched = true;
      });
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
      // this.fetchAll();
    },
  },
};
</script>
